import React from 'react';
import Media from 'react-media';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  Collapse,
  CardSlider,
  Card,
  BREAKPOINTS,
  CARD_SLIDER_TYPES
} from 'day8-react/build/new-components';

import PropTypes from 'prop-types';

export default class WhatIsTSW extends React.Component {
  state = {
    key: 0
  };

  componentDidMount() {
    this.keyHack();
  }

  keyHack = () => this.setState({ key: 1 });

  render() {
    const { data, isChamonix } = this.props;
    const mergedGallery = [];
    data.items.map(item => item.imagelist.map(image => mergedGallery.push(image)));
    const { key } = this.state;
    return (
      <ComponentVerticalSpacing>
        <div className={`tsw-c-what-is-tsw ${isChamonix && 'chamonix'}`}>
          <ComponentWrapper>
            <div className="max-width-xxxs margin-auto">
              <h2 className="margin-auto text-center margin-bottom-md">
                {data.title}
              </h2>
            </div>
            <p className="text-center margin-bottom-xl">
              {data.text}
            </p>
            <Media key={key} query={{ minWidth: BREAKPOINTS.SM }}>
              {matches => matches ? (
                <div className="max-width-lg margin-auto">
                  <div className="grid grid-gap-xl">
                    <div className={`col-3 ${!isChamonix && 'd8-u-base-bg'}`} style={{ zIndex: 3 }}>
                      {data.items.map(item => (
                        <Collapse key={item.title} heading={item.title}>
                          <p>{item.text}</p>
                        </Collapse>
                      ))}
                    </div>
                    <div className="col-9">
                      <CardSlider type={CARD_SLIDER_TYPES.NON_FULL_WIDTH} parentOffset>
                        {mergedGallery.length && mergedGallery.map(image => (
                          <div key={image.image.id} className="flex flex-center margin-x-md">
                            <Card
                              template="ImageCard"
                              size={image.ratio}
                              bgImage={image.image}
                              key={image.image.id}
                            />
                          </div>
                        ))}
                      </CardSlider>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  {data.items.map((item, i) => (
                    <div className="margin-y-xl" key={item.title}>
                      <div className="text-center text-component d8-u-component-padding">
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                      <div className={`home-gallery home-gallery-${i + 1}`}>
                        <CardSlider>
                          {item.imagelist.map((image, i) => (
                            <div key={i} className="flex flex-center margin-x-md">
                              <Card
                                template="ImageCard"
                                size={image.ratio}
                                bgImage={image.image}
                                key={image.image.id}
                              />
                            </div>
                          ))}
                        </CardSlider>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              )}
            </Media>
          </ComponentWrapper>
        </div>
      </ComponentVerticalSpacing>
    );
  }
}


WhatIsTSW.propTypes = {
  data: PropTypes.object,
  isChamonix: PropTypes.bool
};
