import React from 'react';
import Media from 'react-media';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  Svg,
  BREAKPOINTS,
  COLORS,
  Carousel
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

export default class Testimonials extends React.Component {
  state = {
    key: 0
  };

  componentDidMount() {
    this.keyHack();
  }

  keyHack = () => this.setState({ key: 1 });

  render() {
    const { testimonials } = this.props;
    const { key } = this.state;
    return (
      <ComponentVerticalSpacing>
        <ComponentWrapper className="tsw-c-testimoinals max-width-md margin-auto">
          <Media key={key} query={{ minWidth: BREAKPOINTS.SM }}>
            {matches => matches ? (
              <div className="d8-l-auto-grid">
                {testimonials && testimonials.map((item, i) => (
                  <div key={i} className="padding-sm">
                    <div className="d8-c-card d8-u-radius-md margin-bottom-lg d8-u-white-bg padding-xs">
                      <img
                        src={item.card.image.url}
                        className="d8-u-radius-md margin-bottom-lg"
                        alt={item.card.image.alt}
                      />
                    </div>
                    <div className="text-component text-center margin-top-xl">
                      <Svg className="icon--lg" name="quote" fill={COLORS.CONTRAST_HIGHER} />
                      <p>
                        <i>{item.card.text}</i>
                      </p>
                      <p className="d8-u-text-bold"><i>{item.card.by}<br /> {item.card.info}</i></p>
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Carousel template="Children" settings={{ arrows: false }} outerDots>
                {testimonials && testimonials.map((item, i) => (
                  <div key={i} className="padding-sm">
                    <div className="d8-c-card d8-u-radius-md margin-bottom-lg d8-u-white-bg padding-xs">
                      <img
                        src={item.card.image.url}
                        className="d8-u-radius-md margin-bottom-lg"
                        alt={item.card.image.alt}
                      />
                    </div>
                    <div className="text-component text-center margin-top-xl">
                      <Svg className="icon--lg" name="quote" fill={COLORS.CONTRAST_HIGHER} />
                      <p>
                        <i>{item.card.text}</i>
                      </p>
                      <p className="d8-u-text-bold"><i>{item.card.by}<br /> {item.card.info}</i></p>
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                      <Svg className="icon--sm" name="star-fill" fill={COLORS.CONTRAST_HIGHER} />
                    </div>
                  </div>
                ))}
              </Carousel>
            )}
          </Media>
        </ComponentWrapper>
      </ComponentVerticalSpacing>
    );
  }
}


Testimonials.propTypes = {
  testimonials: PropTypes.array
};
