import React from 'react';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  BUTTON_TYPES,
  Card
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

const InfoCards = props => {
  const { infocards } = props;
  return (
    <ComponentVerticalSpacing>
      <ComponentWrapper className="tsw-c-testimoinals max-width-sm margin-auto">
        <div className="d8-l-auto-grid">
          {infocards && infocards.map((item, i) => (
            <Card
              key={i}
              template="ImageCard"
              imageCardTemplate="VerticalSplit"
              heading={item.card.title}
              subheading={item.card.text}
              cta={{
                text: item.card.cta.text,
                link: item.card.cta.url,
                buttonType: BUTTON_TYPES.BARE,
                iconFlip: true,
                icon: 'arrow-right'
              }}
              image={{
                url: item.card.image.url,
                alt: item.card.image.alt
              }}
              imagePadding
            />
          ))}
        </div>
      </ComponentWrapper>
    </ComponentVerticalSpacing>
  );
};


InfoCards.propTypes = {
  infocards: PropTypes.array
};

export default InfoCards;
