import React from 'react';
import {
  ComponentWrapper,
  ComponentVerticalSpacing,
  Svg,
  COLORS
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

const WhatsIncluded = props => {
  const { included } = props;
  return (
    <div className="tsw-c-whats-included d8-u-primary-bg-lower-bg grid">
      <ComponentVerticalSpacing>
        <ComponentWrapper>
          <h2 className="text-center margin-bottom-md">{included.title}</h2>
          <div className="d8-l-sm-auto-grid">
            {included.icons && included.icons.map((item, i) => (
              <div key={i} className="text-center">
                <div className="icon icon--xl d8-u-radius-full d8-u-primary-bg-low-bg flex flex-center margin-auto margin-bottom-xs icon__wrapper">
                  <Svg className="icon--lg" name={item.icon} fill={COLORS.PRIMARY} />
                </div>
                <p className="d8-u-text-bold d8-u-color-contrast-higher">{item.title}</p>
              </div>
            ))}
          </div>
        </ComponentWrapper>
      </ComponentVerticalSpacing>
    </div>
  );
};


WhatsIncluded.propTypes = {
  included: PropTypes.object
};

export default WhatsIncluded;
