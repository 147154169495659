import React, { createContext, useReducer } from 'react';

type Props = {
  children: React.ReactNode
}
type State = {
  showCookieBanner: boolean
}
type Action = {
  type: 'toggle'
}

export const initialValues = {
  showCookieBanner: false,
  toggleShowCookieBanner: () => { }
};

export const GlobalContext = createContext(initialValues);

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'toggle':
      return {
        ...state,
        showCookieBanner: !state.showCookieBanner
      };
  }
};

export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  return (
    <GlobalContext.Provider value={{
      ...state,
      toggleShowCookieBanner: () => dispatch({ type: 'toggle' })
    }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
