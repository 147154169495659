import getConfig from 'next/config';
// Only holds serverRuntimeConfig and publicRuntimeConfig from next.config.js nothing else.
const { publicRuntimeConfig = {} } = getConfig();

const config = {
  // defaults here,
  ...publicRuntimeConfig
};

export default config;
