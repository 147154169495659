import React from 'react';
import {
  Card, ComponentWrapper
} from 'day8-react/build/new-components';
import PropTypes from 'prop-types';

const Intro = props => {
  const { intro } = props;
  return (
    <div className="tsw-c-intro position-relative margin-bottom-xl">
      <div className="text-center">
        <div className="intro-bg d8-u-bg-img width-100% position-absolute" style={{ backgroundImage: `url(${intro.bgimage})`, height: '60%' }} />
        <div className="padding-top-xl">
          <ComponentWrapper>
            <h2 className="text-center margin-bottom-md margin-auto position-relative">
              {intro.title}
            </h2>
            <div className="max-width-sm margin-auto">
              <Card
                template="ImageCard"
                size="aspect-16-9"
                imageCardTemplate="Horizontal"
                video={intro.card.video}
                bgImage={intro.card.image}
                overlay
              />
              {intro.text && <div className="intro-text max-width-xxs margin-auto text-center margin-top-md"><p>{intro.text}</p></div>}
            </div>
          </ComponentWrapper>
        </div>
      </div>
    </div>
  );
};


Intro.propTypes = {
  intro: PropTypes.object
};

export default Intro;
