import React from 'react';
import { GetStaticProps, NextPage } from "next";
import {
  Hero,
  Carousel,
  ComponentWrapper,
  ComponentVerticalSpacing,
  Instagram,
  EmailSignupModal,
  StickyBanner
} from 'day8-react/build/new-components';
import PageLayout from '../layouts/page';
import Destinations from '../components/Destinations';
import WhatIsTSW from '../components/WhatIsTSW';
import Intro from '../components/Intro';
import Testimonials from '../components/HomePage/Testimonials';
import WhatsIncluded from '../components/WhatsIncluded';
import InfoCards from '../components/HomePage/InfoCards';
import BookingDates from '../components/BookingDates';
import AllHighlights from '../components/AllHighlights/AllHighlights';
import wp, { sanitizeDestinationResponse, sanitizeWpResponse } from '../helpers/wp';
import { getDestinations, getSocialFeed } from '../helpers/api';
import { AcfHeroType, DestinationType, WpPageType } from '../globalTypes';

export type FrontPage = {
  bookingdates: WpPageType & {
    acf: {
      bookingdates: []
    }
  }
  page: WpPageType & {
    acf: {
      flex_sticky_banner?: { text: string, link: string }
      heroslides: {
        hero: AcfHeroType
      }[]
      intro: {}
      whatistsw: {}
      included: {}
      testimonials: []
      infocards: []
    }
  }
  isAdsLandingPage?: boolean
  socialFeed: []
  destinations: DestinationType[]
  galleryTags: WpPageType[]
}

const Home: NextPage<FrontPage> = ({
  page,
  destinations,
  bookingdates,
  socialFeed
}
) => {
  return (
    <PageLayout page={page}>
      {
        // Output sticky banner only if some text is entered
        page.acf.flex_sticky_banner && page.acf.flex_sticky_banner.text !== '' && (
          <div className="width-100% position-absolute@sm d8-c-sticky-banner-contain d8-u-zindex-header">
            <StickyBanner
              text={page.acf.flex_sticky_banner.text}
              link={page.acf.flex_sticky_banner.link || null}
            />
          </div>
        )
      }
      <Carousel settings={{ autoplay: true, autoplaySpeed: 7000 }}>
        {page.acf.heroslides.map((slide, i) => (
          <Hero
            key={i}
            title={slide.hero.title}
            subtitle={slide.hero.subtitle}
            bgImage={slide.hero.background}
            video={slide.hero.video}
            poster={slide.hero.poster}
            template={slide.hero.video ? 'Video' : 'TswBasic'}
            cta={slide.hero.button}
            mobileVideo={slide.hero.mobile_video}
            mobilePoster={slide.hero.mobile_poster}
            mobileBgImage={slide.hero.mobile_background}
            modalVideoLink={slide.hero.modalVideoLink}
            overlay
            whiteFont
            centeredDesktop
          />
        ))}
      </Carousel>
      {page.acf.intro && (<Intro intro={page.acf.intro} />)}
      {page.acf.whatistsw && (<WhatIsTSW data={page.acf.whatistsw} />)}
      {destinations && destinations.length > 0 && (
        <React.Fragment>
          <Destinations
            title={`${(destinations && destinations.length) || '3'} Destinations`}
            text="We picked our favourite mountain on each continent. Come ski the world with us!"
            destinations={destinations}
            bgImage="https://assets.theskiweek.com/wp-content/uploads/2020/06/tsw-2.jpg"
          />
          <AllHighlights destinations={destinations} />
        </React.Fragment>
      )}
      {page.acf.included && (
        <WhatsIncluded included={page.acf.included} />
      )}
      {page.acf.testimonials && <Testimonials testimonials={page.acf.testimonials} />}
      {bookingdates && bookingdates.acf && (
        <BookingDates
          bookingdates={bookingdates.acf.bookingdates}
        />
      )}
      <ComponentVerticalSpacing>
        <ComponentWrapper>
          <Instagram socialFeed={socialFeed} />
        </ComponentWrapper>
      </ComponentVerticalSpacing>
      {page.acf.infocards && (<InfoCards infocards={page.acf.infocards} />)}
      {page.acf.signupmodal && page.acf.signupmodal.signupmodal && page.acf.signupmodal.signupmodal.show && (
        <EmailSignupModal
          timeout={10000}
          mobileLink={page.acf.signupmodal.signupmodal.mobilelink}
          title={page.acf.signupmodal.signupmodal.title}
          text={page.acf.signupmodal.signupmodal.text}
          image={page.acf.signupmodal.signupmodal.image}
          addressBookId={page.acf.signupmodal.signupmodal.addressbookid}
        />
      )}
    </PageLayout>
  );
};
export const getStaticProps: GetStaticProps = async () => {
  try {
    const pages: WpPageType[] = await wp.pages().slug('home').get();
    const bookingdates = await wp.pages().slug('booking-dates').get();
    const socialFeed = await getSocialFeed();
    const destinations = await getDestinations();

    // const galleryTags: WpPageType[] = await getGalleryTags() || [];
    return {
      props: {
        page: sanitizeWpResponse(pages[0]),
        destinations: destinations.map((d: DestinationType) => sanitizeDestinationResponse(d, true)),
        socialFeed,
        bookingdates
        // galleryTags: galleryTags.map((g: WpPageType) => ({ id: g.id, name: g.name }))
      },
      revalidate: 300
    };
  } catch (e) {
    console.log({ e });
    return { notFound: true };
  }
};

export default Home;
